import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';

export default {
  name: 'BOQ Files',
  props: ['rfphdrid', 'headerAllData', 'boqAllData', 'negotiationAllData'],
  components: {
    UploadDocument
  },
  data() {
    return {
      loader: false,
      itemBasis: null,
      rfpClosedFlag: false,
      showHistory: false,
      historyType: null,
      rfpHeaderId: null,
      documentData: [],
      documentList: [],
      totalRows: 0,
      selectedGridData: {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      userId: null,
      rfpBoqFilesFlag: false,
      rfpInitiateBidFlag: false,
      editMode: true,
      formType: 'rfpBoqFiles'
    };
  },
  mounted() {
    this.userId = this.$store.state.auth.userId;
    if (this.negotiationAllData && this.negotiationAllData.length > 0) {
      this.rfpClosedFlag = true;
    }
    if (this.rfphdrid) {
      this.rfpHeaderId = this.rfphdrid;
    }
    this.getUploadedDocDtl();
  },
  created() {
    this.eventBus.$on('itemBasis', itemBasis => {
      this.itemBasis = itemBasis;
    });
    this.eventBus.$on('rfpClosedFlag', rfpClosedFlag => {
      this.rfpClosedFlag = rfpClosedFlag;
    });
    this.eventBus.$on('rfpInitiateBidFlag', rfpInitiateBidFlag => {
      this.rfpInitiateBidFlag = rfpInitiateBidFlag;
    });
    this.eventBus.$on('headerId', rfpHeaderId => {
      this.rfpHeaderId = rfpHeaderId;
    });
  },
  methods: {
    getOpenKmAuthTokenUploadDocument() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideHistory(true, 'invoice');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
      this.getUploadedDocDtl();
    },
    getUploadedDocDtl() {
      const payload = {
        _page: 0,
        _limit: 100,
        menu_id: 446,
        sub_menu_id: 90,
        record_id: this.rfpHeaderId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.documentList = response.data.data.page;
              if(this.documentList.length>0) {
                this.rfpBoqFilesFlag = true;
                this.eventBus.$emit('rfpBoqFilesFlag', this.rfpBoqFilesFlag);
              }
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            } else {
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    viewBoqFilesDocuments() {
      this.formType = 'rfpBoqFilesView';
      this.getOpenKmAuthTokenUploadDocument();
    }
  }
};
